import * as React from "react";
import Grid from "@mui/material/Grid";
import FormText from "../../../../../app/form-inputs/FormText";
import { useSelector } from "react-redux";
import {
  getFutureJobCode,
  updateTrEvaluationStepFormData,
} from "../../fmOneSlice";
import FormSelect from "../../../../../app/form-inputs/FormSelect";
import { getTranslated } from "../../../../../app/functions/getTranslatedLevel";
import { EVALUATION_METHODS } from "../../../../../app/constants";

interface ITrEvaluationPage {
  updateFormData: Function;
}

const TrEvaluationPage = ({ updateFormData }: ITrEvaluationPage) => {
  const stepOneFormData = useSelector(
    (state: any) => state.fmEvaluateNewRole.formData.positionDetails
  );
  const trEvaluationStepFormData = useSelector(
    (state: any) => state.fmEvaluateNewRole.formData.trEvaluationStep
  );
  const masterData = useSelector(
    (state: any) => state.fmEvaluateNewRole.masterData.positionDetails
  );
  const fmStateData = useSelector((state: any) => state.fmEvaluateNewRole);

  const updateState = (payload: object) => {
    updateFormData(updateTrEvaluationStepFormData(payload));
  };

  const getFieldNameFromKey = (searchArray: any, key: any) => {
    let name = "";
    searchArray.forEach((data: any) => {
      if (data.value === key) {
        name = data.name;
      }
    });
    return name;
  };

  React.useEffect(() => {
    let futureJobCodePayload = {
      functionCode: stepOneFormData.functionCode,
      subFunctionCode: stepOneFormData.subFunctionCode,
      evaluatedLevelId: trEvaluationStepFormData.evaluatedLevel,
      requestId: fmStateData.requestId,
    };
    if (trEvaluationStepFormData.evaluatedLevel) {
      updateFormData(getFutureJobCode(futureJobCodePayload));
    }
  }, [trEvaluationStepFormData.evaluatedLevel]);

  return (
    <Grid mb={4}>
      <Grid item xs={12} md={12} lg={12} mb={2}>
        <span
          style={{
            backgroundColor: "lightgray",
            padding: "5px 40px 5px 40px",
            borderRadius: "5px",
            fontSize: "110%",
          }}
        >
          <i>{getTranslated("tr.requestDetails") || "Request Details"}</i>
        </span>
      </Grid>
      <Grid container spacing={10} pl={4}>
        <Grid item xs={12} md={6} lg={6}>
          <FormText
            data={{
              label: getTranslated("step1.purposeOfRequestId") || "Purpose of request",
              value: stepOneFormData.purposeOfRequestId,
              name: "purposeOfRequestId",
              readOnly: true,
              multiline: true,
            }}
            updateState={updateState}
            state={fmStateData}
          />
          <FormText
            data={{
              label: getTranslated("step1.newManagerGpid") || "New Manager GPID",
              value: stepOneFormData.newManagerGpid,
              name: "newManagerGpid",
              readOnly: true,
            }}
            updateState={updateState}
          />
          <FormText
            data={{
              label: getTranslated("step1.newManagerName") || "New Manager Name",
              value: stepOneFormData.newManagerName,
              name: "newManagerName",
              readOnly: true,
            }}
            updateState={updateState}
          />
          <FormText
            data={{
              label: getTranslated("step1.incumbentName") || "Incumbent Name",
              value: "N/A",
              name: "incumbentName",
              readOnly: true,
            }}
            updateState={updateState}
          />
          <FormText
            data={{
              label: getTranslated("step1.incumbentGPID") || "Incumbent GPID",
              value: "N/A",
              name: "incumbentGPID",
              readOnly: true,
            }}
            updateState={updateState}
          />
          <FormText
            data={{
              label: getTranslated("tr.positionId") || "Position ID",
              value: "N/A",
              name: "currentPositionId",
              readOnly: true,
            }}
            updateState={updateState}
          />
          <FormText
            data={{
              label: getTranslated("step1.proposedJobTitleId") || "Proposed Job Title",
              value: stepOneFormData.jobTitleId,
              name: "jobTitleId",
              readOnly: true,
            }}
            updateState={updateState}
          />
          <FormText
            data={{
              label: getTranslated("step1.currentEvaluatedLevelId") || "Current Evaluated Level",
              value: "N/A",
              name: "currentEvaluatedLevelId",
              readOnly: true,
            }}
            updateState={updateState}
          />
          <FormText
            data={{
              label: getTranslated("step1.currentCompensatedLevelId") || "Current Compensated Level",
              value: "N/A",
              name: "currentCompensatedLevelId",
              readOnly: true,
            }}
            updateState={updateState}
          />
          <FormText
            data={{
              label: getTranslated("step1.proposedEvaluatedLevelId") || "Proposed Evaluated Level",
              value: getFieldNameFromKey(
                masterData.currentEvaluatedLevels,
                stepOneFormData.proposedEvaluatedLevelId
              ),
              name: "proposedEvaluatedLevelId",
              readOnly: true,
            }}
            updateState={updateState}
          />
          <FormText
            data={{
              label: getTranslated("step1.effectiveDate") || "Effective Date",
              value: stepOneFormData.effectiveDate,
              name: "effectiveDate",
              readOnly: true,
            }}
            updateState={updateState}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <FormText
            data={{
              label: getTranslated("step1.functionCode") || "Function",
              value: getFieldNameFromKey(
                masterData.functions,
                stepOneFormData.functionCode
              ),
              name: "functionCode",
              readOnly: true,
            }}
            updateState={updateState}
          />
          <FormText
            data={{
              label: getTranslated("step1.subFunctionCode") || "Sub Function",
              value: getFieldNameFromKey(
                masterData.subFunctions,
                stepOneFormData.subFunctionCode
              ),
              name: "subFunctionCode",
              readOnly: true,
            }}
            updateState={updateState}
          />
          <FormText
            data={{
              label: getTranslated("step1.countryCode") || "Country",
              value: getFieldNameFromKey(
                masterData.countries,
                stepOneFormData.countryCode
              ),
              name: "countryCode",
              readOnly: true,
            }}
            updateState={updateState}
          />
          <FormText
            data={{
              label: getTranslated("step1.sectorCode") || "Sector",
              value: getFieldNameFromKey(
                masterData.sectors,
                stepOneFormData.sectorCode
              ),
              name: "sectorCode",
              readOnly: true,
            }}
            updateState={updateState}
          />
          <FormText
            data={{
              label: getTranslated("step1.divisionCode") || "Division",
              value: getFieldNameFromKey(
                masterData.divisions,
                stepOneFormData.divisionCode
              ),
              name: "divisionCode",
              readOnly: true,
            }}
            updateState={updateState}
          />
          <FormText
            data={{
              label: getTranslated("step1.regionCode") || "Region",
              value: getFieldNameFromKey(
                masterData.regions,
                stepOneFormData.regionCode
              ),
              name: "regionCode",
              readOnly: true,
            }}
            updateState={updateState}
          />
          <FormText
            data={{
              label: getTranslated("tr.requestorName") || "Requestor Name",
              value: trEvaluationStepFormData.requestorName,
              name: "requestorName",
              readOnly: true,
            }}
            updateState={updateState}
          />
          <FormText
            data={{
              label: getTranslated("tr.submittedDate") || "Request Submission Date",
              value: trEvaluationStepFormData.submittedDate,
              name: "submittedDate",
              readOnly: true,
            }}
            updateState={updateState}
          />
          <FormText
            data={{
              label: getTranslated("tr.snowCaseId") || "ServiceNow Case ID",
              value: trEvaluationStepFormData.snowCaseId,
              name: "snowCaseId",
              readOnly: true,
            }}
            updateState={updateState}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={12} mt={6} mb={2}>
        <span
          style={{
            backgroundColor: "lightgray",
            padding: "5px 40px 5px 40px",
            borderRadius: "5px",
            fontSize: "110%",
          }}
        >
          <i>{getTranslated("tr.evaluationResults") || "Job Evaluation Results"}</i>
        </span>
      </Grid>
      <Grid container spacing={10} pl={4}>
        <Grid item xs={12} md={6} lg={6}>
          <FormSelect
            data={{
              label: getTranslated("tr.evaluatedLevel") || "Evaluated Level",
              value: trEvaluationStepFormData.evaluatedLevel,
              options: masterData.currentEvaluatedLevels,
              name: "evaluatedLevel",
              required: true,
            }}
            updateState={updateState}
          />
          <FormText
            data={{
              label: getTranslated("tr.futureJobCode") || "Future Job Code",
              value: trEvaluationStepFormData.futureJobCode,
              name: "futureJobCode",
              readOnly: fmStateData.futureJobCodeReadonly,
              required: true,
            }}
            updateState={updateState}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <FormSelect
            data={{
              label: getTranslated("tr.evaluationMethod") || "Evaluation Method",
              value: trEvaluationStepFormData.evaluationMethod,
              name: "evaluationMethod",
              options : EVALUATION_METHODS,
              readOnly: true,
            }}
            updateState={updateState}
            // state={fmStateData}
          />
          <FormText
            data={{
              label: getTranslated("tr.evaluatedBy") || "Evaluated By",
              value: trEvaluationStepFormData.evaluatedBy,
              name: "evaluatedBy",
              readOnly: true,
            }}
            updateState={updateState}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TrEvaluationPage;
