import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { ButtonPrimary, ButtonsContainer } from "../../app/styled";
import { getTranslated } from "../../app/functions/getTranslatedLevel";

interface IPaqLandingPage {
  handleNextClick: () => void;
};

const PaqLandingPage = ({ handleNextClick }: IPaqLandingPage) => {
  return (
    <>
      <Container maxWidth="lg">
        <Grid container mb={1} spacing={4}>
          <Grid
            item
            xs={12}
            md={12}
            justifyContent="flex-start"
            alignItems="center"
            display={"flex"}
          >
            <h1>{getTranslated("landing.hello") || "Hello!"}</h1>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            textAlign='left'
          >
            <div>
              <p>
                {getTranslated("landing.welcome") || "Welcome to the Job Evaluation Tool!"}
              </p>
              <p>
                {getTranslated("landing.inThisQuestionnaire") || "In this questionnaire, you will find"}
                <ul>
                  <li>{getTranslated(`landing.description.${0}`) || "structured, simplified, targeted questions"}</li>
                  <li>{getTranslated(`landing.description.${1}`) || "pre-populated position data"}</li>
                  <li>{getTranslated(`landing.description.${2}`) || "clarifying tooltips with detailed explanations"}</li>
                </ul>
              </p>
              <p>
                {getTranslated(`landing.instructions.${0}`) || "Once you submit the questionnaire, your request will be reviewed by the Total Rewards team and you will be notified about the evaluation results."}
                {getTranslated(`landing.instructions.${1}`) || "You will also receive a Case ID that you canuse to track the status of your request in Chester."}
              </p>
            </div>
            <ButtonsContainer style={{ justifyContent: "right" }}>
              <ButtonPrimary onClick={() => handleNextClick()}>
                {getTranslated("buttons.next") || "Next"}
              </ButtonPrimary>
            </ButtonsContainer>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'start'}
          >
            <div>
              <img style={{ display: 'block', maxWidth: '100%' }} alt="" src="/images/landing-page-hero-image.png" />
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default PaqLandingPage;
