import * as React from "react";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import {
  getPositionDetailsMaster,
  updateStepOneFormData,
  getSubFunctionsDropdown,
  getSectorsDropdown,
  getDivisionsDropdown,
  getRegionsDropdown,
  getBusinessUnitsDropdown,
  getMarketUnitsDropdown,
  getWorkLocationsDropdown,
  getFunctionalKpis,
  updateStepFourFormData,
  getSubAreaCode,
  updateStepOneMasterData,
} from "../../paqEvaluateNewRoleSlice";
import FormSelect from "../../../../app/form-inputs/FormSelect";
import FormText from "../../../../app/form-inputs/FormText";
import FormDatePicker from "../../../../app/form-inputs/FormDatePicker";
import { DEFAULT_GPID_REGEX } from "../../../../configs/defaults";
import { DROPDOWN_OPTIONS_MAP, TOOLTIPS } from "../../../../app/constants";
import { getTranslated } from "../../../../app/functions/getTranslatedLevel";
import PurposeOfRequest from "../../../../app/form-inputs/PurposeOfRequest";

interface IPaqStepOne {
  updateFormData: Function;
  disabled?: boolean;
}

const PaqStepOne = ({ updateFormData, disabled = false }: IPaqStepOne) => {
  //States
  const formData = useSelector(
    (state: any) => state.paqEvaluateNewRole.formData.stepOne
  );
  const state = useSelector((state: any) => state.paqEvaluateNewRole);
  const masterData = useSelector(
    (state: any) => state.paqEvaluateNewRole.masterData.stepOne
  );
  const formLockedFromState = useSelector(
    (state: any) => state.paqEvaluateNewRole.formLocked
  );
  const formLocked = formLockedFromState || disabled;
  //step one dispatch
  const updateState = (payload: object) => {
    updateFormData(updateStepOneFormData(payload));
  };
  const updateMasterDataState = (payload: object) => {
    updateFormData(updateStepOneMasterData(payload));
  }

  const updateManagerState = (payload: any) => {
    //Update role context manager name
    updateFormData(updateStepOneFormData(payload));
    let stepFourPayload = {};
    if (payload.newManagerName != undefined) {
      stepFourPayload = { managerName: payload.newManagerName };
    }
    if (payload.newManagerGpid != undefined) {
      stepFourPayload = { managerGPID: payload.newManagerGpid };
    }
    //also update hirerchey manager
    updateFormData(updateStepFourFormData(stepFourPayload));
  };

  //removing below dropdown master data
  const updateBelowDropdownOptions = (key: string) => {
    let belowDropdownOption ={}
    DROPDOWN_OPTIONS_MAP[key].map((element: string)=> {
      belowDropdownOption = {
        ...belowDropdownOption,
        [element]: [] 
      }
    })
    updateMasterDataState(belowDropdownOption);
  }

  //On function change
  React.useEffect(() => {
    if (formData.functionCode != null) {
      //1. get subfunction
      updateFormData(getSubFunctionsDropdown(formData.functionCode));
    }
  }, [formData.functionCode]);
  //On Country change
  React.useEffect(() => {
    //1. drop down generation
    if (formData.countryCode != null) {
      updateBelowDropdownOptions('countryCode');
      updateFormData(getSectorsDropdown(formData.countryCode));
    }
  }, [formData.countryCode]);
  //On sector change change
  React.useEffect(() => {
    if (formData.sectorCode != null) {
      updateBelowDropdownOptions('sectorCode');
      updateFormData(getDivisionsDropdown(formData.sectorCode));
    }
  }, [formData.sectorCode]);
  //On division  change
  React.useEffect(() => {
    if (formData.divisionCode != null) {
      updateBelowDropdownOptions('divisionCode');
      updateFormData(getRegionsDropdown(formData.divisionCode));
    }
  }, [formData.divisionCode]);
  //On region  change
  React.useEffect(() => {
    if (formData.regionCode != null) {
      updateBelowDropdownOptions('regionCode');
      updateFormData(getBusinessUnitsDropdown(formData.regionCode));
    }
  }, [formData.regionCode]);
  //On buisness unit  change
  React.useEffect(() => {
    if (formData.businessUnitCode != null) {
      updateBelowDropdownOptions('businessUnitCode');
      updateFormData(getMarketUnitsDropdown(formData.businessUnitCode));
    }
  }, [formData.businessUnitCode]);
  //On market unit  change
  React.useEffect(() => {
    if (formData.marketUnitCode != null) {
      updateFormData(getWorkLocationsDropdown(formData.marketUnitCode));
    }
  }, [formData.marketUnitCode]);

  React.useEffect(() => {
    // console.log(formData);
  }, [formData]);

  return (
    <>
      <div className="step-one-wrapper">
        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <PurposeOfRequest
              data={{
                label: getTranslated("step1.purposeOfRequestId") || "Purpose of request",
                value: formData.purposeOfRequestId,
                name: "purposeOfRequestId",
                required: true,
                readOnly: true,
                multiline: true,
              }}
              updateState={updateState}
            />
            <FormText
              data={{
                label: getTranslated("step1.newManagerGpid") || "New Manager GPID",
                value: formData.newManagerGpid,
                name: "newManagerGpid",
                required: true,
                readOnly: formLocked,
                regex: DEFAULT_GPID_REGEX,
              }}
              updateState={updateManagerState}
            />
            <FormText
              data={{
                label: getTranslated("step1.newManagerName") || "New Manager Name",
                value: formData.newManagerName,
                name: "newManagerName",
                required: true,
                readOnly: formLocked,
              }}
              updateState={updateManagerState}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.proposedEvaluatedLevelId") || "Proposed Evaluated Level",
                value: formData.proposedEvaluatedLevelId,
                options: masterData.proposedLevels,
                name: "proposedEvaluatedLevelId",
                required: true,
                readOnly: true,
              }}
              updateState={updateState}
            />
            <FormDatePicker
              data={{
                label: getTranslated("step1.effectiveDate") || "Effective Date",
                value: formData.effectiveDate,
                name: "effectiveDate",
                required: true,
                readOnly: formLocked,
                toolTipText: getTranslated("tooltips.effectiveDate") || TOOLTIPS.effectiveDate,
                blockOneMonthBack: true,
              }}
              updateState={updateState}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormSelect
              data={{
                label: getTranslated("step1.functionCode") || "Function",
                value: formData.functionCode,
                options: masterData.functions,
                name: "functionCode",
                required: true,
                readOnly: true,
              }}
              updateState={updateState}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.subFunctionCode") || "Sub Function",
                value: formData.subFunctionCode,
                options: masterData.subFunctions,
                name: "subFunctionCode",
                required: true,
                readOnly: formLocked,
              }}
              updateState={updateState}
            />
            <FormText
              data={{
                label: getTranslated("step1.proposedJobTitleId") || "Proposed Job Title",
                value: formData.proposedJobTitleId,
                name: "proposedJobTitleId",
                readOnly: formLocked,
                noNumeric: true,
                required:true
              }}
              updateState={updateState}
              state={state}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.countryCode") || "Country",
                value: formData.countryCode,
                options: masterData.countries,
                name: "countryCode",
                required: true,
                readOnly: formLocked,
              }}
              updateState={updateState}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.sectorCode") || "Sector",
                value: formData.sectorCode,
                options: masterData.sectors,
                name: "sectorCode",
                required: true,
                readOnly: formLocked,
              }}
              updateState={updateState}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.divisionCode") || "Division",
                value: formData.divisionCode,
                options: masterData.divisions,
                name: "divisionCode",
                required: true,
                readOnly: formLocked,
              }}
              updateState={updateState}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.regionCode") || "Region",
                value: formData.regionCode,
                options: masterData.regions,
                name: "regionCode",
                required: true,
                readOnly: formLocked,
              }}
              updateState={updateState}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.businessUnitCode") || "Business Unit",
                value: formData.businessUnitCode,
                options: masterData.businessUnits,
                name: "businessUnitCode",
                required: true,
                readOnly: formLocked,
              }}
              updateState={updateState}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.marketUnitCode") || "Market Unit",
                value: formData.marketUnitCode,
                options: masterData.marketUnits,
                name: "marketUnitCode",
                required: true,
                readOnly: formLocked,
              }}
              updateState={updateState}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.workLocationCode") || "Work Location",
                value: formData.workLocationCode,
                options: masterData.workLocations,
                name: "workLocationCode",
                required: true,
                readOnly: formLocked,
                toolTipText: getTranslated("tooltips.workLocationCode") || TOOLTIPS.workLocationCode
              }}
              updateState={updateState}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PaqStepOne;
