import * as React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { ToolTip } from "./ToolTip";
import { FieldsToTranslate } from "../constants";
import { getTranslated } from "../functions/getTranslatedLevel";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
interface IFormTextRepeat {
  updateState: Function;
  data: any;
  state?: any
}

const FormTextRepeat = ({ updateState, data, state }: IFormTextRepeat) => {
  /*
  props .data

  {
    label: "Purpose of request",
    value: ["lore ipsum","lore ipsum"],
    name:'purpose_of_request',
    noOfInput: 5,
    step: "stepOne"
  }
  */
  const language = state?.language;

  const translate = () => {
    let text = { q: data.value };
    let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
    url += `&target=${language}`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(text),
    })
      .then((res) => res.json())
      .then((response) => {
        const translations = response.data.translations;
        const detectedSourceLanguage =
          response.data.translations[0].detectedSourceLanguage;
        if (detectedSourceLanguage !== language) {
          console.log(translations);
          const preparedArray = translations.map((item: any) =>{
            return item.translatedText
          })
          updateState({
            [data.name]: preparedArray,
          });
        }
      })
      .catch((error) => {
        console.log("error ", error);
      });
  };

  React.useEffect(() => {
    if (
      data.name === "purposeOfRequestId" ||
      (state?.oktaUserRole === "tr" && FieldsToTranslate.includes(data.name))
    ) {
      translate();
    }
  }, []);

  let arrayValue: any = data.value;
  const handleChange = (i: number, event: any) => {
    let lines = event.target.value.split(/\r|\r\n|\n/);
    let count = lines.length;
    if (count >= 11) {
      return false;
    }
    arrayValue = [...arrayValue];
    arrayValue[i] = event.target.value;
    updateState({
      [data.name]: arrayValue,
    });
  };

  return (
    <div style={{ marginTop: "2em" }}>
      <div className="label">
        <span>{data.label}</span>
        {data.required != undefined && data.required === true && (
          <span style={{ color: "red", marginLeft: 5 }}>*</span>
        )}
        {data.toolTipText != undefined ? (
          <ToolTip title={data.toolTipText} />
        ) : (
          ""
        )}
      </div>

      <div style={{ marginTop: 5 }}>
        <FormControl fullWidth>
          {(() => {
            let textFields = [];
            for (let i = 0; i < data.noOfInput; i++) {
              textFields.push(
                <TextField
                  multiline
                  disabled={data.disabled ? true : false}
                  size="small"
                  style={{ marginBottom: 5 }}
                  error={
                    i === 0 &&
                    arrayValue[i] != undefined &&
                    arrayValue[i] === ""
                  }
                  placeholder={`${data.placeholder} ${i + 1}`}
                  key={i}
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  value={arrayValue[i] != undefined ? arrayValue[i] : ""}
                  onInput={(e) => handleChange(i, e)}
                  maxRows={10}
                  inputProps={{ maxLength: 400 }}
                />
              );
            }
            return textFields;
          })()}
        </FormControl>
        <div className={`input-defination`}>{`${getTranslated('others.maximum') || "Maximum"} 400 ${getTranslated('others.charactersPerBox') || "characters per box"}`}</div>
      </div>
    </div>
  );
};
export default FormTextRepeat;
