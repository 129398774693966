export const FINAL_STEP_MESSAGE =
  "Do you want to submit this form? If you click OK, the form will be submitted and locked. You will not be able to update the answers provided";

export const StringForFileUpload = "uploaded";

export const TRUE_FALSE_OPTIONS = [
  {
    value: "TRUE",
    name: "Yes",
  },
  {
    value: "FALSE",
    name: "No",
  },
];

export const TOOLTIPS = {
  currentCompensatedLevelId:
    "This field will be populated only if Compensated level is different than Evaluated level.",
  effectiveDate:
    "Please note the effective date will be adjusted if needed as per the payroll schedule in employee's country/location. Effective date can only be backdated up to one month.",
  rolePurpose:
    "In a few sentences, describe the main objective, purpose and scope of the role. This section requires a brief specific statement of why the job exists, i.e., the job’s major end result and ultimate purpose. This information is required to enable the evaluator understand the job´s overall fit within the organization.",
  coreAccountability:
    "List in priority order the main accountabilities/deliverables of the role (maximum 6). Accountabilities are statements of the important end results which the job must achieve and the primary responsibilities of the job.  These statements should relate to objective(s) which must be accomplished and against which pre-defined performance KPIs  are applied. At the end of each statement, identify whether the role is accountable for making decisions (A), being informed (I), providing consultation (C) or executing actions relative (R) to the accountability. Also note other relevant jobs or groups that are impacted by the accountability.",
  addInAccountability:
    "Summarize what has changed in this role´s accountability (maximum 6). Changes typically include added or removed accountabilities, scope expansion as well as changes resulting from the business/organizational restructurings. Where a change in accountability is indicated, please describe the specific change, scope shifts and how does it impact broader team/function.",
  functionalKPIs:
    "Please input KPIs directly linked to the scope of the role and areas supported.",
  roleImpactId:
  "Please select the impact the role has on the Business / Functional KPIs in scope.<br><br><b>Primary:</b><br>Controlling impact on end results (at a level of freedom to act).This role is the primary responsible and has direct impact on the end result.<br><br><b>Shared:</b><br>Joint or shared control with other stakeholders for end results within or outside organizational unit in taking action. The impact on end result is direct and measurable.<br><br><b>Contributory:</b><br>Interpretative, advisory or facilitating services for use by others to achieve results. The role makes recommendations and advises for others to make the decisions that directly affects the end result. The impact is indirect.<br><br><b>Remote:</b><br>Provision of information, record keeping or incidental services for use by others in the execution of their job. The impact of the role on end results is limited and not clearly measurable.",
  yearsOfExpId:
    "Indicate the experience required to do the job (might differ from actual employee's practice). It is experience that would be required if we would look for talent in the market.",
  planningHorizonId:
    "Consider the time horizon the job focues on and how often results are revised /controlled.<br><br>Operational and Short Term:<br>Can decide on day to day activities, with manager alignment. Role usually focuses on day to day operations and can make operational decisions with manager alignment.<br><br>Tactical and Mid Term:<br>Focuses on balancing short and long term plans with decision making authority for short term activities and tactics. Mid-term planning needs to be aligned with manager.<br><br>Strategic and Long Term:<br>Focuses on long term agenda with multi-year impact. Has high level of decision making autonomy when it comes to deciding about best course of action to achieve targets.",
  // Level of Decision Making and Impact on Business	TBD
  Stakeholder:
    "Indicate level of roles/stakeholders this job is typically interacting with, both internally (company) and/or externally (e.g vendors, agencies, regulatory bonies, etc.) as well as frequency of this interaction. Consider ONLY the role(s) that is(are) directly influenced on a regular basis (this should exclude direct manager).",
  // Does the role work cross-functionally	TBD
  currentStateOrgChartFileName:
    "Ensure that you provide organizational chart presenting current structure for the job (and their team, if applicable) and include one level up manager with peers in org chart. Organizational charts must include Job Titles, Job Levels, Incumbent Names and Vacancies (if applicable). The attached file can be in jpeg/ png/ pdf/ ms-excel/ msword/ ms-powerpoint/ visio/ spreadsheetml.sheet/ wordprocessingml.document/ presentationml.presentation format and should not exceed 20 MB in size.",
  futureStateOrgChartFileName:
    "Ensure that you provide organizational chart presenting future structure for the job (and their team, if applicable) and include one level up manager with peers in org chart. Organizational charts must include Job Titles, Job Levels, Incumbent Names and Vacancies (if applicable). The attached file can be in jpeg/ png/ pdf/ ms-excel/ msword/ ms-powerpoint/ visio/ spreadsheetml.sheet/ wordprocessingml.document/ presentationml.presentation format and should not exceed 20 MB in size.",
  numberOfMatrixReport:
    "Applies to number of matrix reports in the future structure.",
  headCount: "Applies to total team headcount in the future structure.",
  workLocationCode: "If the role has multiple assignments, please select the primary location. If the work location is unknown, please select the anticipated work location.",
  impactOnBusinessId: "<b>High:</b> Significant decision making autonomy that can directly lead to quantifiable changes in revenue, costs, or customer experience. </br></br> <b>Medium:</b> Ability and responsibility to make regular business decisions that impact other team members and/or customers. </br></br><b>Low:</b> Day-to-day responsibilities largely guided by defined business rules and processes.",
  crossFunctionalityOfRole: "Cross-functional work includes regular (monthly basis at minimum) collaboration and/or support for team members in different functions. If engagement is limited to team members in different sub-functions or sub-areas, please answer “no”.",
  workDelivery: "Please include the Job Title of the role in which the work was previously performed and the reason for the change in role responsibilities. If the work comes from another hierarchical structure, please specify the Function, Sub-Function, and Manager (where available)",
};

export const ROLE_SCOPE_OPTIONS = [
  {
    value: "Global",
    name: "Global",
  },
  {
    value: "Sector",
    name: "Sector",
  },
  {
    value: "BU",
    name: "BU",
  },
  {
    value: "Country/Countries",
    name: "Country/Countries",
  },
];

export const EVALUATION_METHODS = [
  { name: "Hay Method", value: "Hay Method" },
  { name: "Functional Model", value: "Functional Model" },
];

//dynamic dropdown option for position details form
const dropdownValueItems: string[] = ["sectorCode", "divisionCode", "regionCode", "businessUnitCode", "marketUnitCode", "workLocationCode"];
export const DROPDOWN_MAP: any = {
  countryCode: dropdownValueItems,
  sectorCode: dropdownValueItems.slice(1),
  divisionCode: dropdownValueItems.slice(2),
  regionCode: dropdownValueItems.slice(3),
  businessUnitCode: dropdownValueItems.slice(4),
  marketUnitCode: dropdownValueItems.slice(5),
};

const dropdownOptionItems: string[] = ["divisions", "regions", "businessUnits", "marketUnits", "workLocations"];
export const DROPDOWN_OPTIONS_MAP: any = {
  countryCode: dropdownOptionItems,
  sectorCode: dropdownOptionItems.slice(1),
  divisionCode: dropdownOptionItems.slice(2),
  regionCode: dropdownOptionItems.slice(3),
  businessUnitCode: dropdownOptionItems.slice(4)
}

export const FieldsToTranslate = [
  "proposedJobTitleId",
  "rolePurpose",
  "comparableRoles",
  "otherDescription",
  "comment",
  "roleScopeDescription",
  "externalStakeHoldersName",
  "coreAccountability",
  "addInAccountability",
  "crossFunctionalityOfRoleExplanation",
  "workDelivery"
];