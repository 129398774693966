import { LargeNumberLike } from "crypto";
import { Store } from "react-notifications-component";
import { TokenObject } from "../../okta/TokenType";

//Get object from array search by name
export const findLevelObjByName = (value: any, arr: Array<any>) => {
  let obj = arr.find((o: any) => o.name === value);
  if (obj === undefined) {
    obj = {
      value: null,
      name: null,
    };
  }
  return obj;
};

//Notifications
export const successNotice = (title: string, message: string) => {
  Store.addNotification({
    title: title,
    message: message,
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

export const errorNotice = (title: string, message: string) => {
  Store.addNotification({
    title: title,
    message: message,
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};
// show case-id
export const showCaseId = (title: string, message: string) => {
  Store.addNotification({
    title: title,
    message: message,
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    dismiss: {
      duration: 10000,
      onScreen: true,
    },
  });
};

export const warningNotice = (title: string, message: string) => {
  Store.addNotification({
    title: title,
    message: message,
    type: "warning",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

// get proposed compensated level array
export const getProposedCompensatedLevelsArray = (
  proposedLevelList: Array<any>,
  proposedLevel: any
) => {
  // console.log(proposedLevelList, "~~", proposedLevel);
  const index = proposedLevelList.findIndex(
    (x) => x.value === parseInt(proposedLevel)
  );
  // to slice find mix & max index
  const minIndex = index <= 2 ? 0 : index - 2;
  const maxIndex =
    proposedLevelList.length - index <= 2
      ? proposedLevelList.length
      : index + 2;
  const proposedCompensatedLevelArray: any = proposedLevelList.slice(
    minIndex,
    maxIndex + 1
  );
  // console.log("proposedCompensatedLevelArray", proposedCompensatedLevelArray);
  return proposedCompensatedLevelArray;
};

const getToken = () => {
  let tokenString = localStorage.getItem("okta-token-storage");
  if (!tokenString) {
    return;
  }
  const tokenObject = JSON.parse(tokenString) as TokenObject;
  return tokenObject?.accessToken?.accessToken;
};

const createGetHeader = () => {
  return {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
};

const createPostHeader = (payload: any) => {
  return {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(payload),
  };
};

const createPostFileHeader = (formData: any) => {
  return {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    body: formData,
  };
};

export const customFetch = (
  url: string,
  methodType?: string,
  payload?: any
) => {
  return fetch(
    url,
    methodType === "post" ? createPostHeader(payload) : createGetHeader()
  );
};

export const customFetchFile = (url: string, formData: any) => {
  return fetch(url, createPostFileHeader(formData));
};

export const deleteFileNames = (payload: any) => {
  delete payload.currentStateOrgChartFileName;
  delete payload.futureStateOrgChartFileName;
  return payload;
};

//SEND EMAIL
interface emailPayload {
  userName: string;
  userEmail: string;
  requestId: string;
  step: number;
  language: string;
}
const REACT_APP_API_BASE_URI = process.env.REACT_APP_API_BASE_URI;
const API_BASE_URI = REACT_APP_API_BASE_URI;
// window.location.hostname !== "localhost"
//   ? window.location.origin + "/jepc"
//   : REACT_APP_API_BASE_URI;
export const sendEmail = (payload: emailPayload) => {
  return customFetch(`${API_BASE_URI}/send-email`, "post", payload)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject();
    })
    .catch((err) => {
      return Promise.reject();
    });
};

//Validation tr summery page
export const validateTrHr = (payload: any) => {
  let result = true;
  for (const key in payload) {
    // if (key != "futureJobCode") {
      if (payload[key] !== null && payload[key] !== "") {
        result = true;
      } else {
        return false;
      }
    // }
  }
  return result;
};

//Validate regex in payload
export const validateRegexOnPayload = (
  mainDataPayload: any,
  regexCheckObject: Object
) => {
  let result = true;
  let resArr = <any>[];
  Object.entries(regexCheckObject).forEach(([key, regexPattern]) => {
    let pattern = new RegExp(regexPattern);
    let value = mainDataPayload[key];
    if (value != "" && value != null && value != undefined) {
      result = pattern.test(value);
      resArr.push({ resultKey: result });
    }
  });
  if (resArr.find((element: any) => element.resultKey == false)) {
    return false;
  } else {
    return true;
  }
};
